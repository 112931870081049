@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500&family=Playfair+Display:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Playfair+Display:wght@400;500&display=swap');

@tailwind base;

@tailwind components;

@tailwind utilities;


html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Fira Sans', sans-serif;
  scroll-behavior: smooth;
}

body {
  background-color: #171717;
}

h1, h2, h3, h4, h5 {
  font-family: 'Playfair Display', serif;
}

.blog-detail h1 {
  color: white;
}

p, li, a, ol, small, dl, label, div {
  font-family: 'Fira Sans', sans-serif;
}

.wrapper {
  width: 94vw;
  width: clamp(16rem, 94vw, 92rem);
  margin-left: auto;
  margin-right: auto;
  padding-left: .5rem;
  padding-right: .5rem;
  position: relative;
}

.hasChillUnderline {
  display: inline-flex;
  position: relative;
  text-shadow: 1px 1px 2px rgba(150, 150, 150, 0.2);
}
.hasChillUnderline::after {
  display: none;
  content: "";
  position: absolute;
  bottom: 2px;
  left: -6px;
  right: -6px;
  height: 10px;
  background-color: #950A10;
  opacity: .8;
  z-index: -1;
}

@media (min-width: 768px) {
  .hasChillUnderline::after {
      display:block;
  }
}

[x-cloak] { display: none !important; }
/* .blood-bg {
  background-image: url("../../public/img/blood-bg.png") no-repeat center center;
  background-size: cover;
} */


.text-bold {
  font-weight: 800;
}
